/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

import { ReactComponent as Shopee } from 'assets/img/svg/shopee.svg';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
    <ListItem className={classes.listItem}>
      <Tooltip
        id="instagram-tooltip"
        title="Follow us on instagram"
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          color="transparent"
          href="https://www.instagram.com/infinitybeautysg"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-instagram"} /> Instagram
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem}>
      <Tooltip
        id="instagram-facebook"
        title="Follow us on facebook"
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          color="transparent"
          href="https://www.facebook.com/InfinitySkincareSingapore"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-facebook"} /> Facebook
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem}>
        <Tooltip
          id="whatsapp-tooltip"
          title="Message us on WhatsApp"
          placement={"top"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button
          color="transparent"
          href="https://wa.me/6592377355"
          target="_blank"
          className={classes.navLink}
        >
          <i className={classes.socialIcons + " fab fa-whatsapp"} /> Whatsapp
        </Button>
      </Tooltip>
    </ListItem>

  </List>
  );
}
