import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";


import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import keratineresults1 from "assets/img/results/keratineresults1.jpg";
import keratineresults2 from "assets/img/results/keratineresults2.jpg";
import keratineresults3 from "assets/img/results/keratineresults3.jpg";
import keratineresults4 from "assets/img/results/keratineresults4.jpg";
import keratineresults5 from "assets/img/results/keratineresults5.jpg";
import keratineresults6 from "assets/img/results/keratineresults6.jpg";
import keratineresults7 from "assets/img/results/keratineresults7.jpg";
import keratineresults8 from "assets/img/results/keratineresults8.jpg";


const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Results </h2>
          <h4 className={classes.title}>
            Japanese Keratin Lash Lift / 日本蛋白翘睫术
          </h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults1} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults2} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults3} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults4} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults5} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults6} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults7} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                <Card plain>
                  <img src={keratineresults8} alt="keratine results" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}
