import React from "react";
import DocumentMeta from 'react-document-meta';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import LeftNav from "components/Header/LeftNav.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import SectionCarousel from "views/Components/Sections/SectionCarousel.js"

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page

import WorkSection from "./Sections/WorkSection.js";
import LocateSection from "./Sections/LocateSection.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const meta = {
    title: 'Infinity Skin Care Singapore',
    description: 'Home page of Infinity Skin Care Singapore',
    canonical: 'https://www.infinityskincaresg.com/',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'infinity skin care,skin care, singapore skin care, infinity skin care singapore, infinity skin care sg'
        }
    }
  }

  return (
    <div>
      <DocumentMeta {...meta} />  
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Infinity Skin Care"
        leftLinks={<LeftNav />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter responsive image={require("assets/img/infinityskincare-bg-min.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <h1 className={classes.title}>Life might not be perfect but your skin can be</h1>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionCarousel />
          <WorkSection />
          <LocateSection />
          
        </div>
      </div>
      <Footer />
    </div>
  );
}
