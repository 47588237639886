import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import ipl from "assets/img/results/iplresults.jpg";



const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Results </h2>
          <h4 className={classes.title}>
            SHR hair removal / 脱毛类
          </h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <Card plain>
                  <img src={ipl} alt="SHR hair removal" className={imageClasses} style={{width: "100%"}}/>
                </Card>
              </GridItem>
            </Card>
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}
