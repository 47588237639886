/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import CallIcon from '@material-ui/icons/Call';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SpaIcon from '@material-ui/icons/Spa';

import { ReactComponent as Shopee } from 'assets/img/svg/shopee.svg';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list2}>
    <ListItem className={classes.listItem2}>
      <Tooltip
        id="instagram-tooltip"
        title="Look at our services"
        placement={"bottom"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          size="lg"
          color="transparent"
          href="/product-page"
          target="_self"
          className={classes.sidenavLink}
        >
          <SpaIcon className={classes.icons} /> Services
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem2}>
      <Tooltip
        id="instagram-facebook"
        title="Look at the results we produce"
        placement={"bottom"}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          color="transparent"
          href="/result-page"
          target="_self"
          className={classes.sidenavLink}
        >
          <PhotoLibraryIcon className={classes.icons} /> Results
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem2}>
        <Tooltip
          id="whatsapp-tooltip"
          title="Locate us here"
          placement={"bottom"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button
          color="transparent"
          href="/outlet-page"
          target="_self"
          className={classes.sidenavLink}
        >
          <PinDropIcon className={classes.icons} /> Locations
        </Button>
      </Tooltip>
    </ListItem>
    <ListItem className={classes.listItem2}>
        <Tooltip
          id="whatsapp-tooltip"
          title="Contact us here"
          placement={"bottom"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button
          color="transparent"
          href="/#contact_us"
          target="_self"
          className={classes.sidenavLink}
        >
          <CallIcon className={classes.icons} /> Contact us
        </Button>
      </Tooltip>
    </ListItem>

  </List>
  );
}
