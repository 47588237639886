import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import LeftNav from "components/Header/LeftNav.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page


import SkinCareServiceSection from "./Sections/SkinCareServicesSection.js";
// import JapPolaServiceSection from "./Sections/JapPolaServicesSection.js";
// import JapKeratinServiceSection from "./Sections/JapKeratinServicesSection.js";
import IplhairSection from "./Sections/IplhairSection.js";
import CosmeticSection from "./Sections/CosmeticServicesSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ProductPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Infinity Skin Care"
        leftLinks={<LeftNav />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
    <Parallax filter responsive image={require("assets/img/infinityskincare-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <h1 className={classes.title}>Life might not be perfect but your skin can be</h1>
              <br />
            </GridItem>
          </GridContainer>
        </div>
    </Parallax>

    <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>

          

            <SkinCareServiceSection />
            {/* <JapPolaServiceSection /> */}
            {/* <JapKeratinServiceSection /> */}
            <IplhairSection />
            <CosmeticSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
