import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// react component for creating beautiful carousel
import Carousel from "react-slick";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// images for this page
import outlet1 from "assets/img/OutletImg.jpeg";
import outlet2 from "assets/img/OutletImg2.jpeg";
import outlet3 from "assets/img/OutletImg3.jpeg";


import InfinityMapContainer  from "components/Map/Map.js";



const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Outlets </h2>
          <h4 className={classes.title}>
            Infinity Beauty Singapore
          </h4>
          <h4 className={classes.title}>
            <LocationOn className="slick-icons" />
            Location: 206 Toa Payoh N #01-1223<br/>
            Postal code : S310206
          </h4>

        </GridItem>
      </GridContainer>
      <div>
        <GridContainer> 
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                <Card carousel>
                  <Carousel {...settings}>
                    <img src={outlet3} alt="infinity outlet" className="slick-image" />
                    <img src={outlet2} alt="infinity outlet" className="slick-image" />
                    <img src={outlet1} alt="infinity outlet" className="slick-image" />
                  </Carousel>
                </Card> 
              </GridItem>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
            <br/>
            <InfinityMapContainer />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
}
