import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { InstagramEmbed } from 'react-social-media-embed';

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import MapContainer  from "components/Map/Map.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
                <h2 id="locate_us"className={classes.title}>Locate us</h2>
                <h4 className={classes.title}>
                    <LocationOn className="slick-icons" />
                    Location: 206 Toa Payoh N #01-1223<br/>
                    Postal code : S310206
                </h4>
            </GridItem>
        </GridContainer>

        <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={6}>
                <h4 className={classes.title}>Directions</h4>
                
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <InstagramEmbed url="https://www.instagram.com/p/C5BKrK1hWuq/" width={328} />
                </div>

            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.title}>Map</h4>
                <MapContainer />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </GridItem>
        </GridContainer> 

    </div>
  );
}
