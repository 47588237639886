/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import mdiWeChat from 'mdi-material-ui/Wechat';
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";

import whatsappQR from "assets/img/qrcode/qr-code-whatsapp.png";
import wechatQR from "assets/img/qrcode/infinityqr.jpeg";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function WorkSection() {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  const [classicModal2, setClassicModal2] = React.useState(false);
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 id="contact_us"className={classes.title}>Contact us</h2>          
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
          <a target="_blank" rel="noopener noreferrer" href="http://fb.me/msg/InfinitySkincareSingapore">
            <InfoArea
              title="Facebook"
              description="Message us on Facebook @ http://fb.me/msg/InfinitySkincareSingapore"
              icon={FacebookIcon}
              iconColor="info"
              vertical
            />
          </a> 
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/infinitybeautysg/">
              <InfoArea
                title="Instagram"
                description="Find us on Instagram @ https://www.instagram.com/infinitybeautysg/"
                icon={InstagramIcon}
                iconColor="primary"
                vertical
              />
            </a>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <a rel="noopener" href="JavaScript: void()" onClick={() => setClassicModal(true)}>
              <InfoArea
                title="WhatsApp"
                description="Message or Call us through WhatsApp @ +65 9237 7355 "
                icon={WhatsAppIcon}
                iconColor="success"
                vertical
                
              />
            </a>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={classicModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setClassicModal(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setClassicModal(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 className={classes.modalTitle}>Scan the QR Code to chat with us! :)</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <img src={whatsappQR} width="300" height="300" alt="WhatsApp QR code"></img>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => setClassicModal(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <a rel="noopener" href="JavaScript: void()" onClick={() => setClassicModal2(true)}>
              <InfoArea
                title="WeChat"
                description="Message or Call us through WeChat"
                icon={mdiWeChat}
                iconColor="success"
                vertical
              />
            </a>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={classicModal2}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setClassicModal2(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setClassicModal2(false)}
                >
                  <Close className={classes.modalClose} />
                </IconButton>
                <h4 className={classes.modalTitle}>Scan the QR Code to chat with us! :)</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <img src={wechatQR} width="300" height="300" alt="Wechat QR code"></img>
              </DialogContent>
              <DialogActions className={classes.modalFooter}>
                <Button
                  onClick={() => setClassicModal2(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
